// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import "assets/utils/variables";
// @import '../node_modules/bootstrap/scss/bootstrap.scss';
// @import "@angular/material/prebuilt-themes/deeppurple-amber.css";
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$online_forecasting_solution_web-primary: mat.define-palette($primary);
$online_forecasting_solution_web-accent: mat.define-palette($accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$online_forecasting_solution_web-warn: mat.define-palette($warn);
$custom-typography: mat.define-typography-config($font-family: "Open Sans",
  );
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$online_forecasting_solution_web-theme: mat.define-light-theme((color: (primary: $online_forecasting_solution_web-primary,
        accent: $online_forecasting_solution_web-accent,
        warn: $online_forecasting_solution_web-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($online_forecasting_solution_web-theme);
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";


html,
body {

  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans" !important;
}

.width-100 {
  width: 100% !important;
}

.height-100 {
  height: 100% !important;
}

.bg-standard {
  background: #ffffff !important;
}

.layout-full-sidenav>header,
.layout-full-sidenav>.header {
  grid-column-start: 2;
  grid-row-start: 1;
}

.padding-1 {
  padding: 0.5em 0.5em 0.5em 0.5em !important;
}

.padding-0-1-1-1 {
  padding: 0em 0.5em 0.5em 0.5em !important;
}

.layer-5 {
  z-index: 5000 !important;
}

.position-relative {
  position: relative !important;
}

.layout-full-sidenav {
  display: grid;
  grid-template-columns: auto minmax(min-content, 1fr); // auto;
  grid-template-rows: auto minmax(min-content, 1fr) auto;
}

.layout-full-sidenav>.nav-container {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: span 4;
  background: #1a1628;
}

// .layout-full-sidenav > .nav-container > nav, .layout-full-sidenav > .nav-container > .nav {
.primary-nav {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr;
  background: linear-gradient(180deg,
      #1a1628 -1.83%,
      #1a1628 -1.82%,
      rgba(2, 45, 66, 0.1) 6.39%,
      rgba(113, 110, 121, 0.4) 50.47%,
      #2f2c3c 111%);
  padding: 0.5em 0em 1.5em 0em;
  overflow: hidden;
}

.flex-justify-center {
  justify-content: center !important;
}

.display-flex {
  display: flex !important;
}

.padding-0-0-1-0 {
  padding: 0em 0em 0.5em 0em !important;
}

.padding-2-0-0-0 {
  padding: 1em 0em 0em 0em !important;
}

.toolbar.toolbar-vertical {
  flex-direction: column;
  height: 100%;
}

.toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.toolbar.toolbar-vertical.toolbar.toolbar-labels-below>a {
  text-align: center;
}

.layout-full-sidenav>.nav-container>nav .link-action,
.layout-full-sidenav>.nav-container>.nav .link-action {
  width: 5em;
  background: transparent;
}

.toolbar.toolbar-labels-below>a {
  flex-direction: column;
}

.toolbar.toolbar-vertical>a {
  padding: 0.75em 0.5em 0.75em 0.5em;
}

.master-style .link-action {
  color: #2f2c3c;
  background: transparent;
}

.toolbar>a {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 1em 1em 1em;
}

.layout-full-sidenav>.nav-container>nav .side-nav-label,
.layout-full-sidenav>.nav-container>.nav .side-nav-label {
  font-size: 0.6875em;
  font-weight: 600;
}

.toolbar.toolbar-labels-below>a>* {
  padding: 0em 0em 0em 0em;
}

// .toolbar > a > *:last-child {
//   padding-right: 0;
// }
.toolbar>a>.icon+*,
.toolbar>a>* {
  // padding: 0em 0.33333333em 0em 0.33333333em;
  cursor: pointer;
  font-size: 11px;
}

.secondaryBtn {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  // display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  border-radius: 0px;
  margin-left: 4px;
  border: 1px solid #b2b0b6;
  color: #454250 !important;
}

.secondaryIcon {
  color: white;
  -webkit-text-stroke: 1px grey;
}

.mat-button-toggle {
  color: #716e79 !important;
  border-radius: 0px;
}

// .mat-button-toggle:hover {
//   background-color: #27A6A4;
//   color: #ffffff;
// }

.mat-button-toggle-checked {
  background-color: #27a6a4;
  color: #ffffff !important;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border-radius: 0px !important;
}

// remove border of input text

.mat-mdc-dialog-container .mdc-dialog__surface,
.mdc-notched-outline__leading,
.mdc-notched-outline__trailing {
  border-radius: 0px !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  font-family: inherit !important;  
  letter-spacing: inherit !important;
  color: #2F2C3C !important;
}


.dialog-backdrop {
  background-color: rgba(22, 83, 82, 0.6);
  overflow: hidden;
}

.mat-mdc-table .mdc-data-table__row {
  background: rgba(0, 0, 0, 0.02);
  border-bottom: 2px solid #ffff;
}

.mat-mdc-table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
}

.dialog-message {
  color: #1A1628;
  padding-bottom: 5em;
}

.buttonAdd {
  color: #FFFFFF !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  // display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  border-radius: 0px;
  margin-left: 4px;
  border-radius: 0 !important;
}

.container {
  max-width: 100%;
}

.dropdown-content {
  display: none;
  // position: absolute;
  // background-color: #f9f9f9;
  // min-width: 160px;
  // box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  // z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

//   .dropdown-content a:hover {
//     background-color: #ddd;
//   }

.dropdown:hover .dropdown-content {
  display: block;
}

.buttonAdd {
  color: #FFFFFF !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  // display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  border-radius: 0px;
}

.secondaryBtn {

  border: 1px solid #B2B0B6;
  color: #454250 !important;

}

.mat-mdc-table-sticky-border-elem-top {
  border-bottom: 1px solid #716E79;
}


.mat-button-toggle-group {
  height: 40px;
  align-items: center;
}

.mat-form-field-outline-start,
.mat-form-field-outline-end {
  border-radius: 0 !important;
}

.pe-pointer {
  cursor: pointer;
}


.close-dialog-icon {
  position: absolute;
  right: 1em;
  top: 1em;
  cursor: pointer;

}

.buttonSecondary {
  border: 1px solid #B2B0B6 !important;
  color: #454250 !important;
  border-radius: 0 !important;
}

.buttonPositive {
  border: 1px solid #B2B0B6 !important;
  color: #201f22 !important;
  border-radius: 0 !important;
  background-color: #dbf0f0 !important;
}

.mat-icon {
  height: auto !important;
  width: 18px !important;
  overflow: inherit !important;
}

button {
  .mat-icon {
    display: flex;
    width: 24px !important;
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.row {
  margin: 0;
}

.mat-mdc-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02) !important;
  border-radius: 0 !important;
}

// .mdc-dialog__actions{
//   padding:0px !important;
// }

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  bottom: 4.5px;
  // left: -10px;
}

.switchDisabled {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  bottom: 4.5px;
  // left: -10px;
  opacity: 0.5;
  pointer-events: none;
}

button.disabled {
  pointer-events: none;
  opacity: 0.5;
}

button.disabled mat-icon {
  filter: grayscale(100%);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switchDisabled input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #27A6A4;
}

input:focus+.slider {
  // box-shadow: 0 0 1px #27A6A4;
}

input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 1 !important;
  transform: translateY(0px) !important;
}

.mat-sort-header-arrow[style] {

  // Hide default arrow stem
  .mat-sort-header-stem {
    display: none;
  }

  .mat-sort-header-indicator {
    opacity: 1;
    color: #716E79;
    font-weight: bold;
    font-size: 20px;
    transform: translateY(5px) !important;

    // Hide default arrow as its composed of left, right and middle
    .mat-sort-header-pointer-left,
    .mat-sort-header-pointer-right,
    .mat-sort-header-pointer-middle {
      display: none;
    }
  }
}

// My custom ascending arrow
[aria-sort="ascending"] {
  .mat-sort-header-arrow {
    .mat-sort-header-indicator {
      &::before {
        content: "\2191";
      }
    }
  }
}

// My custom descending arrow
[aria-sort="descending"] {
  .mat-sort-header-arrow {
    .mat-sort-header-indicator {
      &::before {
        content: "\00a0 \2193";
      }
    }
  }
}

// My custom no sort
.mat-sort-header-arrow {
  .mat-sort-header-indicator {
    &::before {
      content: "\2191 \2193";
    }
  }
}

::-webkit-scrollbar {
  width: .45rem;
  height: .45rem;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(27, 27, 27, .4);
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.ngx-pagination .current {
  padding: 0.1875rem 0.625rem;
  background: #27a6a4 !important;
  color: #fefefe;
  cursor: default;
}

.mat-dialog {
  transition: none;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #27A6A4;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mat-mdc-cell {
  background: #FAFAFA !important;
  // background: #f4f3f3 !important;

}

.layout-main-section {

  position: relative;


  main {

    position: absolute;

    display: flex;

    flex-direction: column;

    height: 100%;

    width: 100%;
    // background-color: red;




    header,

    footer {

      flex-shrink: 0;

      height: fit-content;

    }




    section {

      position: relative;

      overflow-y: auto;

      height: 100vh;

    }

  }

}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

input.mat-mdc-chip-input {
  flex: 1 0 150px;
  margin-left: 0px !important;
}


.emptyMessage {
  border-radius: 2px;
  // background-color: #f3f1f1;
  background-color: white;
  padding: .5em 1.5em;
  transform: translate(-38%, -50%) !important;
}

.emptyMessageList {
  border-radius: 2px;
  background-color: #F4F3F3;
  padding: .5em 1.5em;
  transform: translate(-50%, 123%) !important;
}

.cdk-overlay-pane:has(.download-model-id) {
  width: min-content !important;
}

.emptyMessageWhite {
  border-radius: 2px;
  // background-color: #ffffff;
  padding: .5em 1.5em;
  // transform: translate(-38%,-50%)!important;
}

.mat-mdc-paginator {
  display: block;
  border-top: 1px solid #dcdbdb;
}

.mat-mdc-paginator-outer-container {
  display: flex;
  left: 36%;
  position: absolute;
}

:root {
  --element-height: 65vh;
}

@media (min-resolution: 120dpi) {
  :root {
    --element-height: 57vh;
  }
}


.dynamic-height {
  // for scaling 100%
  // height: 65vh !important;
  height: calc(100vh - 325px) !important;


  @media (min-resolution: 120dpi) {
    // for scaling 125%
    height: 57vh !important;
    //  height: calc(100vh - 400px) !important;

  }

  @media (min-resolution: 144dpi) {
    // for scaling 150%
    height: 44vh !important // height: calc(100vh - 425px) !important;

  }

  padding-left: 5px;
}


.dynamic-height-FC {
  // for scaling 100%
  // height: 63vh !important;
  height: calc(100vh - 325px) !important;


  @media (min-resolution: 120dpi) {
    // for scaling 125%
    height: 57vh !important;
  }

  @media (min-resolution: 144dpi) {
    // for scaling 150%
    height: 44vh !important
  }

  padding-left: 5px;
}

::ng-deep .mat-mdc-paginator-range-actions{
  margin-left:6em !important;
}

::ng-deep  .mat-mdc-paginator-outer-container{
  margin-left: 5em;
}

.mat-mdc-select-placeholder{
  font-style: italic;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  font-style: italic;
}

.mat-mdc-tooltip {
  position: relative;
  font-size: 0.8rem;

  &::after {
    overflow: initial;
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid #616161;
  }
}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 50%;
  width: auto !important;
  transform: translate(-50%, -50%);
}

/* General styles for disabled native HTML inputs */
::ng-deep input[disabled], 
::ng-deep textarea[disabled], 
::ng-deep select[disabled] {
    color: #6c757d; /* Slightly darker text color for better readability */
    border: 1px solid #ced4da; /* Soft border color */
    opacity: 1; /* Override default opacity to make them more visible */
}

/* Specific styles for disabled mat-form-field */
::ng-deep mat-form-field[disabled] .mat-input-element,
::ng-deep mat-form-field[disabled] .mat-select-value {
    color: #6c757d; /* Slightly darker text color for better readability */
    border: 1px solid #ced4da; /* Soft border color */
    opacity: 1; /* Override default opacity to make them more visible */
}

/* Ensure mat-select looks good when disabled */
::ng-deep .mat-select[disabled] .mat-select-placeholder,
::ng-deep .mat-select[disabled] .mat-select-value {
    color: #6c757d; /* Slightly darker text color for better readability */
}

/* Disable pointer events but keep the visual style */
::ng-deep .mat-form-field-disabled .mat-input-element,
::ng-deep .mat-form-field-disabled .mat-select-value {
    pointer-events: none;
}
